
import Vue, { VueConstructor } from "vue";

import BasisDailyProjection from "./main/BasisDailyProjection.vue";
import BasisWeeklyProjection from "./main/BasisWeeklyProjection.vue";
import BasisMonthlyProjection from "./main/BasisMonthlyProjection.vue";

import { IParams } from "@/types/store-types";
import BasisMixins from "@/mixins/basisAnalysis";
import Growing from "@/models/growing";
import UserBasisCity from "@/models/userBasisCity";

import { IQuotationsDate, QuotationsDate } from "@/business/Quotations";

import { utilsService } from "@/services/utils";

interface IBasisWeeklyProjectionComponent {
  loadProjectionTableChart?: () => void;
  loadProjectionSocket?: () => void;
}

interface IBasisMonthlyProjectionComponent {
  loadProjectionTableChart?: () => void;
  loadProjectionSocket?: () => void;
}

interface IBasisDailyProjectionComponent {
  loadProjectionDailyChart?: () => void;
  loadProjectionSocket?: () => void;
}

interface IQuotationParams {
  growing: string;
  items: {
    dollar: Array<string>;
    se: {
      cbot?: Array<string>;
      b3?: Array<string>;
    };
  };
}

export default (
  Vue as VueConstructor<
    Vue & InstanceType<typeof BasisMixins>
  >
).extend({
  components: {
    BasisWeeklyProjection: BasisWeeklyProjection,
    BasisMonthlyProjection: BasisMonthlyProjection,
    BasisDailyProjection: BasisDailyProjection,
  },
  mixins: [BasisMixins],

  data() {
    return {
      // open form
      dialog: false,
      // Class com as cidades que temos basis
      basisCitiesUser: [] as UserBasisCity[],
      // Milho: muda a bolsa
      basisAsset: "cbot" as string,
      // Lista com cotações mensal
      monthlyQuotes: new QuotationsDate(),
      // Lista com cotações com rolagem de contrato
      weeklyQuotes: new QuotationsDate(),
      // Cultura selecionada
      selectedGrowing: undefined as Growing | undefined,
      // Controle progress linear load
      loading: true,
      result: new QuotationsDate(),
      localLoading: false,
    };
  },

  sockets: {
    connect(): void {
      console.log("socket connected");
      if (this.$socket.connected) {
        this.$socket.client.emit("join_projection");
      }
    },
    room_joined(data): void {
      console.log(`user has joined the room: ${data}`);
    },
    update_quotes(): void {
      console.log('updating projection quotes...')
      const params = this.quotationParam();
      this.$socket.client.emit("update_projection_quotes", params);
    },
    projection_quote_result(data): void {
      if (data) {
        const jsonData = JSON.parse(data);
        this.socketUpdateQuotes(jsonData);
      }
    },
  },

  // MOUNTED
  async mounted() {
    if (this.$socket.disconnected) {
      this.$socket.client.open();
    }
    this.loading = true;
    await this.loadGrowing();
    await this.getUserCities();
    await this.getQuotes();
    this.loading = false;
    this.$nextTick(() => {
      this.basisDailyProjectionLoad();
      this.basisWeeklyProjectionLoad();
      this.basisMonthlyProjectionLoad();
    });
  },

  methods: {
     // MAIN FUNCTION
    async execute(): Promise<void> {
      this.loading = true;
      this.basisAsset = 'cbot';
      await this.getUserCities();
      await this.getQuotes();
      this.loading = false;
      this.$nextTick(() => {
        this.basisDailyProjectionLoad();
        this.basisWeeklyProjectionLoad();
        this.basisMonthlyProjectionLoad();
      });
    },

    quotationParam(): IQuotationParams {
      const dates = this.genDate();
      let params: IQuotationParams | undefined = undefined;

      if (this.selectedGrowing?.isCorn) {
        params = {
          growing: 'corn',
          items: { dollar: dates, se: { b3: dates, cbot: dates } },
        };
      } else {
        params = {
          growing: 'soybean',
          items: { dollar:  dates, se: { cbot: dates } },
        };
      }
      return params;
    },

    async getQuotes(): Promise<void> {
      if (this.selectedGrowing && this.selectedGrowing.id) {
        const params = this.quotationParam();
        try {
          const response = await utilsService.getQuotationsByArrayOfDate(
            params
          );
          if (response) {
            this.result = response
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.warn("getQuotes: no growing selected");
      }
    },

    genDate(): Array<string> {
      let todays = new Date();
      let monthlyDate = new Date(
        todays.getFullYear() + "-1" + "-1")
        .toISOString()
        .split("T")[0];
      let dates: string[] = [];
      for (let month = 0; month < 24; month++) {
        dates.push(
          this.$luxon(monthlyDate)
            .plus({ months: month })
            .toString()
            .substr(0, 10)
        );
      }
      if (todays.getDate() >= 15) {
        let weeklyDate = new Date().toISOString().split("T")[0]
        for (let month = 0; month < 2; month++) {
          dates.push(
            this.$luxon(weeklyDate)
              .plus({ months: month })
              .toString()
              .substr(0, 10)
          );
        }
      }
      return dates;
    },

    async loadGrowing(): Promise<void> {
      this.selectedGrowing = this.growings.find(
        (g: Growing) => g.symbol === "S"
      );
    },

    async basisDailyProjectionLoad(fromSocket=false): Promise<void> {
      const BasisDailyProjectionComp = this.$refs[
        "BasisDailyProjectionComp"
      ] as IBasisDailyProjectionComponent;
      if (fromSocket) {
        if (BasisDailyProjectionComp?.loadProjectionSocket) {
          await BasisDailyProjectionComp.loadProjectionSocket();
        }
      } else {
        if (BasisDailyProjectionComp?.loadProjectionDailyChart) {
          await BasisDailyProjectionComp.loadProjectionDailyChart();
        }
      }
    },

    async basisWeeklyProjectionLoad(fromSocket=false): Promise<void> {
      const BasisWeeklyProjectionComp = this.$refs[
        "BasisWeeklyProjectionComp"
      ] as IBasisWeeklyProjectionComponent;
      if (fromSocket) {
        if (BasisWeeklyProjectionComp?.loadProjectionSocket) {
          await BasisWeeklyProjectionComp.loadProjectionSocket();
        }
      } else {
        if (BasisWeeklyProjectionComp?.loadProjectionTableChart) {
          await BasisWeeklyProjectionComp.loadProjectionTableChart();
        }
      }
    },

    async basisMonthlyProjectionLoad(fromSocket=false): Promise<void> {
      const BasisMonthlyProjectionComp = this.$refs[
        "BasisMonthlyProjectionComp"
      ] as IBasisMonthlyProjectionComponent;
      if (fromSocket) {
        if (BasisMonthlyProjectionComp?.loadProjectionSocket) {
          await BasisMonthlyProjectionComp.loadProjectionSocket();
        }
      }else{
        if (BasisMonthlyProjectionComp?.loadProjectionTableChart) {
          await BasisMonthlyProjectionComp.loadProjectionTableChart();
        }
      }
    },

    async changeStockExchange(value: string): Promise<void> {
      this.basisAsset = value;
      this.$nextTick(() => {
        this.basisDailyProjectionLoad();
        this.basisWeeklyProjectionLoad();
        this.basisMonthlyProjectionLoad();
      });
    },
    // SOCKET UPTADE QUOTATIONS
    socketUpdateQuotes(jsonData: IQuotationsDate): void {
      this.result = new QuotationsDate(jsonData);
      this.$nextTick(() => {
        this.basisDailyProjectionLoad(true);
        this.basisWeeklyProjectionLoad(true);
        this.basisMonthlyProjectionLoad(true);
      });
    },
    // GET USER'S DATA
    async getUserCities(): Promise<void> {
      const params: IParams = {
        user_id: this.user?.id,
        growing_id: this.selectedGrowing?.id,
      };
      try {
        await this.getUserBasisCities(params);
        this.basisCitiesUser = this.userBasisCities;
      } catch (error) {
        this.$alertError("Erro ao carregar os embarques");
        console.log(error);
      }
    },
    async setGrowing(symbol: string): Promise<void> {
      this.selectedGrowing = this.growings.find((el: Growing) => el.symbol === symbol);
      await this.execute();
    }
  },

  computed: {
    growingsAvailable(): Array<Growing> {
      return this.growings.filter(
        (el: Growing) => el.symbol == "S" || el.symbol == "C"
      );
    },

    availableBasisCities(): UserBasisCity[] | undefined {
      return this.basisCitiesUser;
    },
    weeklyData(): QuotationsDate {
      return this.result;
    },
    monthlyData(): QuotationsDate {
      return this.result;
    }
  },

  beforeDestroy() {
    if (this.$socket.connected) {
      this.$socket.client.emit("leave_projection");
      console.log("user left the room");
      this.$socket.client.close();
    }
  },
});
