
import Vue, { VueConstructor } from "vue";

import BasisMonthyProjectionTable from "../tables/BasisMonthlyProjectionTable.vue";

import Growing from "@/models/growing";
import { BasisProjectionData } from "@/business/BasisProjection";
import { basisProjectionService } from "@/services/basis_projection";
import { IBasisProjectionParams } from "@/types/service-types";
import { QuotationsDate } from "@/business/Quotations";


export default (Vue as VueConstructor<Vue>).extend({
  props: {
    selectedGrowing: Growing,
    basisCity: String,
    basisAsset: String,
    monthlyQuotes: QuotationsDate,
  },
  components: {
    MonthTable: BasisMonthyProjectionTable,
  },

  data() {
    return {
      // Historico mensal para projeção
      basisMonthlyProjection: [] as Array<BasisProjectionData>,
      // controla o skelleton
      loading: false,
      // Controla msg de erro
      error: false,
      // Monstra no grafico
      errorMessage: "",
      crudSkelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
    };
  },

  methods: {
    async loadProjectionTableChart(): Promise<void> {
      this.loading = true;
      await this.getBasisMonthlyProjection();
      this.loading = false;
    },
    async loadProjectionSocket(): Promise<void> {
      await this.getBasisMonthlyProjection();
    },
    // GET BASIS DATA
    async getBasisMonthlyProjection(): Promise<void> {
      this.error = false;
      try {
        let params: IBasisProjectionParams = {
          growing: this.selectedGrowing?.symbol,
          period: "monthly",
          se: this.basisAsset.toUpperCase(),
        };
        this.basisMonthlyProjection = await basisProjectionService.getBasisProjectionMonthly(
          params
        );
      } catch (error) {
        console.error(error);
        this.error = true;
      }
    },
  },

  computed: {
    basisMonthlyProjectionData(): any {
      return this.basisMonthlyProjection;
    }
  },
});
