
import Vue, { VueConstructor } from "vue";

import Growing from "@/models/growing";
import constants from "@/constants";

import { QuotationsDate } from "@/business/Quotations";
import { BasisProjectionData, } from "@/business/BasisProjection";

interface IDataControl {
  oldItems: Array<any>,
  newItems: Array<any>,
  controlAnimation: boolean
}

interface DisplayMonthlyData {
  city: string,
  month_1: number,
  month_2: number,
  month_3: number,
  month_4: number,
  month_5: number,
  month_6: number,
  month_7: number,
}

interface DisplayHeaders {
  align: string,
  text: string,
  value: string,
  tooltip: string,
}

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    growingSelected: Growing,
    monthlyQuotes: QuotationsDate,
    basisMonthlyProjectionData: Array as () => BasisProjectionData[],
    basisAsset: String,
    loading: Boolean,
  },
  data: () => ({
    show: false,
    search: '',
    dataControl: Object as unknown as IDataControl,
    controlAnimation: false,
    errorMessage: 'Nenhuma cidade encontrada',
    headers: [
      {
        text: "Cidade",
        tooltip: "Cidade Referencia",
        align: "left",
        value: "city",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_1",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_2",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_3",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_4",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_5",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_6",
      },
      {
        text: "",
        tooltip: "Data de referência",
        align: "center",
        value: "month_7",
      },
    ],
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    crudClass:
      "font-weight-bold font-weight-black caption text-md-h5 text-sm-subtitle-1",
  }),

  methods: {
    addMonths(numOfMonths: number) {
      let now = new Date()
      let date = new Date(now.getFullYear(), now.getMonth(), 1)
      date.setMonth(date.getMonth() + numOfMonths);
      return date;
    },

    defineHeaders() {
      let datesHeader = []
      for (let month = 1; month <= 7; month++) {
        datesHeader.push({
          month: month,
          month_date: this.addMonths(month)
        })
      }
      return datesHeader;
    },

    blink(item: any, head: string): string {
      if (this.dataControl.controlAnimation) {
        let itemClassTrue = ''
        if (this.dataControl.oldItems && this.dataControl.oldItems.length) {
          this.dataControl.oldItems.filter((old: any) => {
            let datetime = new Date()
            if (old.city === item.city && (( datetime.getTime() - item.time.getTime() ) / 1000 ) < 2) {
              if (old[head] < item[head]) {
                itemClassTrue = 'blink-less-true'
              } else if (old[head] === item[head]) {
                itemClassTrue = 'blink-even-true'
              } else {
                itemClassTrue = 'blink-great-true'
              }
            }
          })
        }
        return itemClassTrue;
      } else {
        let itemClassFalse = ''
        if (this.dataControl.oldItems && this.dataControl.oldItems.length) {
          this.dataControl.oldItems.filter((old: any) => {
            let datetime = new Date()
            if (old.city === item.city && (( datetime.getTime() - item.time.getTime() ) / 1000 ) < 2) {
              if (old[head] < item[head]) {
                itemClassFalse = 'blink-less-false'
              } else if (old[head] === item[head]) {
                itemClassFalse = 'blink-even-false'
              } else {
                itemClassFalse = 'blink-great-false'
              }
            }
          })
        }
        return itemClassFalse;
      }
    },

    calcSackPrice(dollar: number, se: number, basis: number): number {
      let rs_sc = 0;
      let constant = constants.get(this.growingSelected.symbol);

      if (this.basisAsset === "cbot") {
        if (constant) {
          rs_sc = ((basis + se) * constant.x * dollar) / 100;
        }
      } else {
        rs_sc = basis + se;
      }
      return rs_sc;
    },

    calcItems(): DisplayMonthlyData[] {
      const headerMonths = this.defineHeaders()
      const months_ref = headerMonths.map((el) => el.month_date.toISOString().split('T')[0])

      const results: DisplayMonthlyData[] = []
      if (this.basisMonthlyProjectionData && this.basisMonthlyProjectionData.length) {
        this.basisMonthlyProjectionData.map((data) => {
          let ref_city = data.city;
          let month_value: any[] = [];
          let basis_value: any = 0;
          let dollar_value: any = 0;
          let result_value: any = 0;

          months_ref.map((month, idx) => {
            basis_value = data.history.find((el) =>
              el.date == new Date(month).getMonth().toString()
            )?.value || 0

            let se_value: any = 0;
            if (this.basisAsset === 'cbot') {
              se_value = this.monthlyQuotes.se.cbot.find((el) => el.date == month);
              dollar_value = this.monthlyQuotes.dollar.find((el) => el.date == month);
              result_value = this.calcSackPrice(
                dollar_value?.value,
                se_value?.value,
                basis_value
              );
            } else {
              se_value = this.monthlyQuotes.se.b3.find((el) => el.date == month);
              result_value = basis_value + se_value?.value;
            }

            month_value.push({
              ['month_' + (idx + 1).toString()]: parseFloat(result_value?.toFixed(2)),
            })
          })

          let satan = month_value.reduce(((r, c) => Object.assign(r, c)), {})
          satan.city = ref_city
          satan.time = new Date()
          results.push(satan)
        })
      }

      this.$store.commit("setMonthlyProjectionDataTable", results);
      return results
    },
    setHeaders(): DisplayHeaders[] {
      const headerMonths = this.defineHeaders()
      headerMonths.map((el) => {
        let month_name = el.month_date.toLocaleString('pt-BR', { month: 'short' }).replace(/\./, '')
        month_name = month_name.charAt(0).toUpperCase() + month_name.slice(1)
        let year_date = el.month_date.getFullYear().toString().substr(-2);

        this.headers.map((head) => {
          if (head.value === 'month_'+ el.month)
          head.text = month_name + '-' + year_date
        });
      });
      return this.headers
    },

  },

  computed: {
    monthlyProjectionItems(): DisplayMonthlyData[] {
      return this.calcItems()
    },
    displayHeaders(): DisplayHeaders[] {
      return this.setHeaders()
    }
  },
  watch: {
    monthlyProjectionItems(_new, _old) {
      this.controlAnimation = !this.controlAnimation
      this.dataControl.oldItems = _old
      this.dataControl.newItems = _new
      this.dataControl.controlAnimation = this.controlAnimation
    },
  }
});
