
import Vue, { VueConstructor } from "vue";

import BasisWeeklyProjectionTable from "../tables/BasisWeeklyProjectionTable.vue";

import Growing from "@/models/growing";
import { BasisProjectionData, BasisMarketProjectionData } from "@/business/BasisProjection";
import { basisProjectionService } from "@/services/basis_projection";
import { IBasisProjectionParams } from "@/types/service-types";
import { QuotationsDate } from "@/business/Quotations";

export default (
  Vue as VueConstructor<Vue>).extend({
    props: {
      selectedGrowing: Growing,
      basisAsset: String,
      weeklyQuotes: QuotationsDate,
    },
    components: {
      WeekTable: BasisWeeklyProjectionTable,
    },

    data() {
      return {
        // Historico semanal para projeção
        basisWeeklyProjection: [] as Array<BasisProjectionData>,
        // Cash Market value
        basisMarketProjection: [] as Array<BasisMarketProjectionData>,
        // controla o skelleton
        loading: false,
        // Controla msg de erro
        error: false,
        // Monstra no grafico
        errorMessage: "",
        crudSkelletonAttrs: {
          class: "mb-6",
          elevation: 0,
        },
      };
    },

    methods: {
      async loadProjectionTableChart(): Promise<void> {
        this.loading = true;
        await this.getBasisWeeklyProjection();
        await this.getBasisMarketProjection();
        this.loading = false;
      },

      async loadProjectionSocket(): Promise<void> {
        await this.getBasisWeeklyProjection();
        await this.getBasisMarketProjection();
      },
      // GET BASIS DATA
      async getBasisWeeklyProjection(): Promise<void> {
        this.error = false;
        try {
          let params: IBasisProjectionParams = {
            growing: this.selectedGrowing?.symbol,
            period: "weekly",
            se: this.basisAsset.toUpperCase(),
          };

          this.basisWeeklyProjection = await basisProjectionService.getBasisProjectionWeekly(
            params
          );
        } catch (error) {
          console.error(error);
          this.error = true;
        }
      },

      // GET TODAYS BASIS DATA
      async getBasisMarketProjection(): Promise<void> {
        this.error = false;
        try {
          let params: IBasisProjectionParams = {
            growing: this.selectedGrowing?.symbol,
            period: "cash_market",
            se: this.basisAsset.toUpperCase(),
          };
          this.basisMarketProjection = await basisProjectionService.getBasisMarketProjection(
            params
          );
        } catch (error) {
          console.error(error);
          this.error = true;
        }
      },
    },

    computed: {
      basisWeeklyProjectionData(): any {
        return this.basisWeeklyProjection;
      },
      basisMarketProjectionData(): any {
        return this.basisMarketProjection;
      }
    },
  });
