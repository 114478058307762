
import Vue, { VueConstructor } from "vue";

import Growing from "@/models/growing";
import constants from "@/constants";

import { QuotationsDate } from "@/business/Quotations";
import { BasisProjectionData, BasisMarketProjectionData } from "@/business/BasisProjection";


interface IDisplayItems {
  id: number,
  city: string,
  price: number,
  basis_actual: number,
  basis_history: number,
  basis_diff: number,
  time: Date
}

interface IDataControl {
  oldItems: Array<IDisplayItems>,
  newItems: Array<IDisplayItems>,
  controlAnimation: boolean
}

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    growingSelected: Growing,
    weeklyQuotes: QuotationsDate,
    basisWeeklyProjectionData: Array as () => BasisProjectionData[],
    basisMarketProjectionData: Array as () => BasisMarketProjectionData[],
    basisAsset: String,
    loading: Boolean,
  },
  data: () => ({
    show: false,
    dataControl: Object as unknown as IDataControl,
    controlAnimation: false,
    errorMessage: 'Nenhuma cidade encontrada',
    search: '',
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    headers: [
      {
        text: 'Cidade',
        value: 'city',
      },
      {
        text: 'Preço',
        value: 'price',
        align: 'center',
      },
      {
        text: 'Basis Atual',
        value: 'basis_actual',
        align: 'center',
      },
      {
        text: 'Basis Histórico',
        value: 'basis_history',
        align: 'center',
      },
      {
        text: 'Variação',
        value: 'basis_diff',
        align: 'center',
      },

    ],
  }),
  methods: {
    blink(item: any,): string {
      if (this.dataControl.controlAnimation) {
        let itemClassTrue = ''
        if (this.dataControl.oldItems && this.dataControl.oldItems.length) {
          this.dataControl.oldItems.filter((old: any) => {
            let datetime = new Date()
            if (old.id === item.id && (( datetime.getTime() - item.time.getTime() ) / 1000 ) < 2) {
              if (old.basis_diff < item.basis_diff) {
                itemClassTrue = 'blink-less-true'
              } else if (old.basis_diff === item.basis_diff) {
                itemClassTrue = 'blink-even-true'
              } else {
                itemClassTrue = 'blink-great-true'
              }
            }
          })
        }
        return itemClassTrue;
      } else {
        let itemClassFalse = ''
        if (this.dataControl.oldItems && this.dataControl.oldItems.length) {
          this.dataControl.oldItems.filter((old: any) => {
            let datetime = new Date()
            if (old.id === item.id && (( item.time.getTime() - datetime.getTime() ) / 1000 ) < 2) {
              if (old.basis_diff < item.basis_diff) {
                itemClassFalse = 'blink-less-false'
              } else if (old.basis_diff === item.basis_diff) {
                itemClassFalse = 'blink-even-false'
              } else {
                itemClassFalse = 'blink-great-false'
              }
            }
          })
        }
        return itemClassFalse;
      }
    },
    getColor (item: any): string {
      let color = '#fa0'; // dark yellow
      if (this.dataControl && this.dataControl.oldItems){
        this.dataControl.oldItems.filter((old: any) => {
          if (old.id === item.id) {
            if (old.basis_diff < item.basis_diff) {
              color = '#FF6962' // dark red
            } else if (old.basis_diff === item.basis_diff) {
              color ='#fa0' // dark orange
            } else {
              color = '#90C87E' // dark green
            }
          }
        })
      }
      return color
    },
    calcSackPrice(dollar: number, se: number, basis: number): number {
      let rs_sc = 0;
      let constant = constants.get(this.growingSelected.symbol);

      if (this.basisAsset === "cbot") {
        if (constant) {
          rs_sc = ( ((basis / dollar) / constant.x) * 100 ) - se;
        }
      } else {
        rs_sc = basis - se;
      }
      return rs_sc;
    },

  },
  computed: {
    displayItems(): IDisplayItems[] {
      const results: IDisplayItems[] = []
      let se_code = this.basisMarketProjectionData[0]?.code || ''
      let se_value = 0;
      let dollar_value = 0;

      dollar_value = this.weeklyQuotes.dollar.find((qt) => qt.code === 'PTAX')?.value || 0
      if (this.basisAsset === 'b3') {
        se_value = this.weeklyQuotes.se.b3.find((qt) => qt.code === se_code)?.value || 0
      } else if (this.basisAsset === 'cbot') {
        se_value = this.weeklyQuotes.se.cbot.find((qt) => qt.code === se_code)?.value || 0
      }

      this.basisMarketProjectionData.map((el: BasisMarketProjectionData) => {
        let actual_basis = 0;
        let historical_basis = 0;

        const basis_history_data = this.basisWeeklyProjectionData.find((wb) =>
          wb?.city.split('-')[0] === el.city &&
          wb.city.split('-')[1] === el.state
        )?.history || []

        if (el?.cash_market && se_value) {
          if (this.basisAsset === 'cbot'){
            actual_basis = this.calcSackPrice(
              dollar_value,
              se_value,
              el?.cash_market
            )
          } else {
            actual_basis = el?.cash_market - se_value;
          }
        }

        if (basis_history_data && basis_history_data.length) {
          historical_basis = basis_history_data.find((bh) => el.week === bh.week)?.value || 0;
        }
        results.push({
          id: results.length + 1,
          city: el.city + '-' + el.state,
          price: el.cash_market,
          basis_actual: actual_basis,
          basis_history: historical_basis,
          basis_diff: actual_basis - historical_basis,
          time: new Date()
        })
      })
      return results
    }
  },
  watch: {
    displayItems(_new, _old) {
      this.controlAnimation = !this.controlAnimation
      this.dataControl.oldItems = _old
      this.dataControl.newItems = _new
      this.dataControl.controlAnimation = this.controlAnimation
    },
  }
});
