
import Vue, { VueConstructor } from "vue";

import BasisDailyProjectionChart from "../charts/BasisDailyProjectionChart.vue";
import BasisDailyProjectionChartSplit from "../charts/BasisDailyProjectionChartSplit.vue";

import Growing from "@/models/growing";
import { BasisChartProjectionData } from "@/business/BasisProjection";
import { basisProjectionService } from "@/services/basis_projection";
import { IBasisProjectionParams } from "@/types/service-types";
import { QuotationsDate } from "@/business/Quotations";
import UserBasisCity from "@/models/userBasisCity";

interface IChartComponent {
  generateChart?: () => void;
}

export default (
  Vue as VueConstructor<Vue>).extend({
  props: {
    selectedGrowing: Growing,
    basisCitiesUser: Array as () => UserBasisCity[],
    basisAsset: String,
  },
  components: {
    DailyChart: BasisDailyProjectionChart,
    DailyChartSplit: BasisDailyProjectionChartSplit,
  },

  data() {
    return {
      // Cash Market value
      basisChartProjectionData: new BasisChartProjectionData(),
      // data inicial
      startDate: new Date(new Date().getFullYear() - 1, 0, 1)
        .toISOString().split('T')[0],
      // data final
      endDate: new Date(new Date().getFullYear(), 11, 31)
        .toISOString().split('T')[0],
      // controla o skelleton
      loading: false,
      // COTACOES
      dailyQuotes: new QuotationsDate(),
      // Controla msg de erro
      error: false,
      // CIDADE SELECIONADA A
      citySelectedA: new UserBasisCity(),
      // CIDADE SELECIONADA B
      citySelectedB: new UserBasisCity(),
      // minimun date
      minDate: new Date(new Date().getFullYear() - 5, 0, 1)
        .toISOString().split('T')[0],
      maxDate: new Date(new Date().getFullYear() + 2, 11, 31)
        .toISOString().split('T')[0],

      // Monstra no grafico
      errorMessage: "Nenhuma cidade encontrada",
      crudSkelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
    };
  },

  created() {
    this.initLoad();
  },

  methods: {
    async loadProjectionDailyChart(): Promise<void> {
      this.loading = true;
      await this.getBasisChartData();
      this.loading = false;
      this.$nextTick(() => {
        this.chartGeneration();
        this.chartGenerationSplit();
      })
    },

    async loadProjectionSocket(): Promise<void> {
      await this.getBasisChartData();
      this.$nextTick(() => {
        this.chartGeneration();
        this.chartGenerationSplit();
      })
    },

    async initLoad(): Promise<void> {
      if (this.basisCitiesUser) {
        if (this.basisCitiesUser.length > 1) {
          this.citySelectedA = this.basisCitiesUser[0];
          this.citySelectedB = this.basisCitiesUser[1];
        } else if (this.basisCitiesUser.length == 1) {
          this.citySelectedA = this.basisCitiesUser[0];
          this.citySelectedB = this.basisCitiesUser[0];
        }
        await this.loadProjectionDailyChart();
      } else {
        this.error = true;
      }
    },

    getItemText(item: any) {
      return item.city + ' - ' + item.state;
    },

    // GET BASIS DATA
    async getBasisChartData(): Promise<void> {
      this.error = false;
      try {
        let params: IBasisProjectionParams = {
          growing: this.selectedGrowing?.symbol,
          se: this.basisAsset.toUpperCase(),
          period: "chart_data",
          start_date: this.startDate,
          end_date: this.endDate,
          cities: [this.citySelectedA?.city, this.citySelectedB?.city].join(','),
          states: [this.citySelectedA?.state, this.citySelectedB?.state].join(',')
        };
        this.basisChartProjectionData = await basisProjectionService.getBasisProjectionChart(
          params
        );
      } catch (error) {
        console.error(error);
        this.error = true;
      }
    },

    chartGeneration(): void {
      const execChart = this.$refs[
        "BasisDailyProjectionChart"
      ] as IChartComponent;
      if (execChart && execChart.generateChart) {
        execChart.generateChart();
      }
    },
    chartGenerationSplit(): void {
      const execChart = this.$refs[
        "BasisDailyProjectionChartSplit"
      ] as IChartComponent;
      if (execChart && execChart.generateChart) {
        execChart.generateChart();
      }
    },
    async findResults(): Promise<void> {
      await this.loadProjectionDailyChart()
    }
  },

  computed: {
    basisChartData(): BasisChartProjectionData {
      return this.basisChartProjectionData;
    },

    cityA(): UserBasisCity {
      return this.citySelectedA;
    },

    cityB(): UserBasisCity {
      return this.citySelectedB;
    }
  },
  watch: {
    async basisCitiesUser() {
      await this.initLoad();
    }
  }
});
