
import Vue from "vue"
import { mapGetters} from "vuex";

import Growing from "@/models/growing";
import constants from "@/constants";

import { BasisChartProjectionData, BasisMarketProjectionData } from "@/business/BasisProjection";
import { IBasisChartOptions } from "@/business/HistoryBasis";
import UserBasisCity from "@/models/userBasisCity";


export default Vue.extend({
  props: {
    growingSelected: Growing,
    basisChartProjectionData: BasisChartProjectionData,
    basisCitiesUserA: UserBasisCity,
    basisCitiesUserB: UserBasisCity,
    endDate: String,
    basisAsset: String,
  },

  data: () => ({
    optionsChartA: undefined as IBasisChartOptions | undefined,
    optionsChartB: undefined as IBasisChartOptions | undefined,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    colors: [
      '#066F6A',
      '#D1AE6C',
      '#2C3F7A',
      '#990000',
      '#32C5E9',
      '#67E0E3',
      '#9FE6B8',
      '#ff9f7f',
      '#fb7293',
      '#E690D1',
      '#9d96f5',
      '#8378EA',
      '#96BFFF'
    ],
    localError: false,
    cityNameA: "",
    cityNameB: "",
  }),

  methods: {

    genDate(): Array<string> {
      let todays = new Date();
      let todaysYear = todays.getFullYear()
      let todaysMonth = todays.getMonth()

      let monthlyDate = new Date(
        todaysYear,
        todaysMonth,
        16
      ).toISOString().split("T")[0];

      let dates: string[] = [];

      for (let month = 0; month < 8; month++) {
        dates.push(
          this.$luxon(monthlyDate)
            .plus({ months: month + 1})
            .toString()
            .substr(0, 10)
        );
      }
      return dates;
    },

    calcSackPrice(dollar: number, se: number, basis: number): number {
      let rs_sc = 0;
      let constant = constants.get(this.growingSelected.symbol);

      if (this.basisAsset === "cbot") {
        if (constant) {
          rs_sc = ((basis + se) * constant.x * dollar) / 100;
        }
      } else {
        rs_sc = basis + se;
      }
      return rs_sc;
    },


    calcChartData(city: string): any {
      let five_years_data = [] as any;
      // GET HISTORY DATA CITY
      const todaysBasis = this.basisChartProjectionData.five_years.find((bc) =>
        bc?.city === city
      )
      if (todaysBasis) {
        five_years_data = todaysBasis?.history;
      }
      // GET CASH MARKET DATA CITY
      let cashMarketData = this.basisChartProjectionData.cash_market.filter((el) =>
        [el.city, el.state].join('-') === city
      )
      // GET PROJECTION VALUES CITY
      let historyData: any[] = []
      let sackPrice = 0;
      cashMarketData.filter((el) => {
        if (el.code !== 'projection') {
          const dollar_value = el?.dollar_settle || 0;
          const basis_value = five_years_data.find((fy: any) => fy.week === el.week)?.value || 0;
          const se_value = el?.se_settle || 0;

          if (this.basisAsset === 'cbot') {
            sackPrice = this.calcSackPrice(
              dollar_value,
              se_value,
              basis_value
            )
          } else {
            sackPrice = basis_value + se_value;
          }

          historyData.push(sackPrice);
        }
      });

      let dates = this.genDate()
      this.monthlyProjectionDataTable.map((el: any) => {
        if (el.city === city) {
          for (let i=0; i < 7; i++) {
            let month_key = 'month_' + (i+1).toString()
            cashMarketData.push(new BasisMarketProjectionData(
              {
                cash_market: parseFloat(el[month_key].toString()),
                city: city.split('-')[0],
                code: "projection",
                date: dates[i],
                growing: this.growingSelected.symbol,
                se: this.basisAsset.toUpperCase(),
                se_settle: 0,
                dollar_settle: 0,
                state: city.split('-')[1],
                week: 1,
              })
            )
          }
        }
      })

      // PROJECTIN A DATA
      let cashMarketProjection = [] as BasisMarketProjectionData[]
      cashMarketProjection.push(
        cashMarketData[cashMarketData.filter((el) => el.code !== 'projection').length - 1]
      )
      cashMarketData.filter((el: any) => {
        if (el.code === 'projection') {
          cashMarketProjection.push(el)
        }
      })

      let projectionData = cashMarketProjection.map((el) => {
        if (el && el.date) {
          let date = new Date(el.date).toLocaleDateString("pt-BR", {
            year: "2-digit",
            month: "short",
            day: "2-digit",
          })
          let dateBr = date.replaceAll(' de ', ' ').replace('.', '').split(' ')
          let capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
          let dateFormatted = capitalize(dateBr[1])
          let dateB = dateBr[0] + ' ' + dateFormatted + ' ' + dateBr[2]
          return {
            value: [dateB, el.cash_market.toFixed(2)]
          }
        }
      })
      return {
        cashMarketData: cashMarketData,
        historyData: historyData,
        projectionData: projectionData
      }
    },

    generateChart(): void {
      let today = new Date()
      let endDateObj = new Date(this.endDate)
      let shouldProjection = true

      this.cityNameA = [this.basisCitiesUserA.city, this.basisCitiesUserA.state].join('-')
      this.cityNameB = [this.basisCitiesUserB.city, this.basisCitiesUserB.state].join('-')

      let dataChartA = this.calcChartData(this.cityNameA)
      let dataChartB = this.calcChartData(this.cityNameB)

      if (endDateObj < today ) {
        shouldProjection = false
      }

      if (
        dataChartA.cashMarketData.length === 0 ||
        dataChartA.historyData.length === 0 ||
        dataChartB.cashMarketData.length === 0 ||
        dataChartB.historyData.length === 0
      ) {
        this.localError = true;
        console.log('no data found')
        return
      }

      let titleChartA = this.cityNameA.toUpperCase()
      let titleChartB = this.cityNameB.toUpperCase()

      let legendsChartA = [
        'Cash Market ' + this.cityNameA,
        'Histórico + ' + this.basisAsset.toUpperCase() + ' ' + this.cityNameA,
      ]
      let legendsChartB = [
        'Cash Market ' + this.cityNameB,
        'Histórico + ' + this.basisAsset.toUpperCase() + ' ' + this.cityNameB,
      ]

      let datesChartA = dataChartA.cashMarketData.map((el: any) => el.date)
      let datesChartB = dataChartB.cashMarketData.map((el: any) => el.date)

      let datesObjectsChartA = datesChartA.map((el: any) => {
        let date = new Date(el).toLocaleDateString("pt-BR", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        })
        let dateBr = date.replaceAll(' de ', ' ').replace('.', '').split(' ')
        let capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
        let dateFormatted = capitalize(dateBr[1])
        return dateBr[0] + ' ' + dateFormatted + ' ' + dateBr[2]
      })

      let datesObjectChartB = datesChartB.map((el: any) => {
        let date = new Date(el).toLocaleDateString("pt-BR", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        })
        let dateBr = date.replaceAll(' de ', ' ').replace('.', '').split(' ')
        let capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
        let dateFormatted = capitalize(dateBr[1])
        return dateBr[0] + ' ' + dateFormatted + ' ' + dateBr[2]
      })

      let conditionalSeriesA = [] as any[]
      let conditionalSeriesB = [] as any[]
      conditionalSeriesA = [
        {
          name: 'Cash Market ' + this.cityNameA,
          type: "line",
          smooth: true,
          data: dataChartA.cashMarketData.map((el: any) => {
            if (el.code !== 'projection') {
              return el.cash_market.toFixed(2)
            }
          }),
          lineStyle: {
            width: 2,
            type: "solid",
          },
        },
        {
          name: 'Histórico + ' + this.basisAsset.toUpperCase() + ' ' + this.cityNameA,
          type: "line",
          smooth: true,
          data: dataChartA.historyData.map((el: any) => el.toFixed(2)),
          lineStyle: {
            width: 2,
            type: "solid",
          },
        }
      ]
      conditionalSeriesB = [
        {
          name: 'Cash Market ' + this.cityNameB,
          type: "line",
          smooth: true,
          data: dataChartB.cashMarketData.map((el: any) => {
            if (el.code !== 'projection') {
              return el.cash_market.toFixed(2)
            }
          }),
          lineStyle: {
            width: 2,
            type: "solid",
          },
        },
        {
          name: 'Histórico + ' + this.basisAsset.toUpperCase() + ' ' + this.cityNameB,
          type: "line",
          smooth: true,
          data: dataChartB.historyData.map((el: any) => el.toFixed(2)),
          lineStyle: {
            width: 2,
            type: "solid",
          },
        },
      ]

      if (shouldProjection) {
        legendsChartA.splice(2, 0, 'Projeção ' + this.cityNameA)
        legendsChartB.splice(2, 0, 'Projeção ' + this.cityNameB)

        // this.colors.splice(2, 0, 'red')
        // this.colors.splice(5, 0, 'orange')

        conditionalSeriesA.splice(2, 0, {
          name: 'Projeção ' + this.cityNameA,
          type: "line",
          smooth: true,
          data: dataChartA.projectionData,
          lineStyle: {
            width: 2,
            type: "dashed",
            // color: 'red'
          },
        })
        conditionalSeriesB.splice(2, 0, {
          name: 'Projeção ' + this.cityNameB,
          type: "line",
          smooth: true,
          data: dataChartB.projectionData,
          lineStyle: {
            width: 2,
            type: "dotted",
            // color: 'orange'
          },
        })
      }

      this.optionsChartA = {
        // color: this.colors,
        title: {
          left: "10%",
          text: titleChartA,
          textStyle: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
            fontSize: 15,
          },
        },
        legend: {
          data: legendsChartA,
          top: '10%'
        },
        tooltip: {
          trigger: 'axis',
          // position: (pt: any) => {
          //   return [pt[0], '10'];
          // },
          formatter:(params: any) => {
            let output = '<b>' + params[0].name + '</b><br/>'
            for (let i = 0; i < params.length; i++) {
              if (params[i].value){
                if (typeof params[i].value !== 'object') {
                  output += params[i].marker + params[i].seriesName + ': ' + params[i].value;
                  if (i != params.length - 1) { // Append a <br/> tag if not last in loop
                    output += '<br/>'
                  }
                }else {
                  output += params[i].marker + params[i].seriesName + ': ' + params[i].value[1];
                  if (i != params.length - 1) { // Append a <br/> tag if not last in loop
                    output += '<br/>'
                  }
                }
              }
            }
            return output
          }
        },
        axisPointer: {
          link: {
            xAxisIndex: "all",
          },
          label: {
            backgroundColor: "#777",
          },
        },
        grid: [
          {
            left: "10%",
            right: "2%",
            bottom: "20%",
            top: "20%",
            height: "60%",
          },
        ],
        xAxis: [{ data: datesObjectsChartA }],
        yAxis: [
          {
            type: "value",
            name: "R$/sc",
            splitLine: { show: false },
            scale: true,
            splitNumber: 5,
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            boundaryGap: [0, "100%"],
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
              formatter: (param: string) => {
                return "R$ " + param;
              },
              fontSize: 12,
            },
            axisTick: {
              interval: 0,
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
          }
        ],
        toolbox: {
          show: true,
          right: "10%",
          feature: {
            // dataView: {show: true, readOnly: false},
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                line: "Linhas",
                bar: "Barras",
              },
            },
            restore: {
              show: true,
              title: "Atualizar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "orange",
                borderWidth: 2,
              },
            },
            saveAsImage: {
              show: true,
              title: "Salvar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "green",
                borderWidth: 2,
              },
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
            handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "80%",
            handleStyle: {
              color: "#fff",
              shadowBlur: 3,
              shadowColor: "rgb(29, 41, 81)",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
          },
        ],
        series: conditionalSeriesA
      };

      this.optionsChartB = {
        // color: this.colors,
        title: {
          left: "10%",
          text: titleChartB,
          textStyle: {
            color: this.$vuetify.theme.dark ? "#fff" : "#000",
            fontSize: 15,
          },
        },
        legend: {
          data: legendsChartB,
          top: '10%'
        },
        tooltip: {
          trigger: 'axis',
          // position: (pt: any) => {
          //   return [pt[0], '10'];
          // },
          formatter:(params: any) => {
            let output = '<b>' + params[0].name + '</b><br/>'
            for (let i = 0; i < params.length; i++) {
              if (params[i].value){
                if (typeof params[i].value !== 'object') {
                  output += params[i].marker + params[i].seriesName + ': ' + params[i].value;
                  if (i != params.length - 1) { // Append a <br/> tag if not last in loop
                    output += '<br/>'
                  }
                }else {
                  output += params[i].marker + params[i].seriesName + ': ' + params[i].value[1];
                  if (i != params.length - 1) { // Append a <br/> tag if not last in loop
                    output += '<br/>'
                  }
                }
              }
            }
            return output
          }
        },
        axisPointer: {
          link: {
            xAxisIndex: "all",
          },
          label: {
            backgroundColor: "#777",
          },
        },
        grid: [
          {
            left: "10%",
            right: "2%",
            bottom: "20%",
            top: "20%",
            height: "60%",
          },
        ],
        xAxis: [{ data: datesObjectChartB }],
        yAxis: [
          {
            type: "value",
            name: "R$/sc",
            splitLine: { show: false },
            scale: true,
            splitNumber: 5,
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            boundaryGap: [0, "100%"],
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
              formatter: (param: string) => {
                return "R$ " + param;
              },
              fontSize: 12,
            },
            axisTick: {
              interval: 0,
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
          }
        ],
        toolbox: {
          show: true,
          right: "10%",
          feature: {
            // dataView: {show: true, readOnly: false},
            magicType: {
              show: true,
              type: ["line", "bar"],
              title: {
                line: "Linhas",
                bar: "Barras",
              },
            },
            restore: {
              show: true,
              title: "Atualizar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "orange",
                borderWidth: 2,
              },
            },
            saveAsImage: {
              show: true,
              title: "Salvar",
              iconStyle: {
                color: "rgba(255,255,255,0)",
                borderColor: "green",
                borderWidth: 2,
              },
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
            handleIcon:
              "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "80%",
            handleStyle: {
              color: "#fff",
              shadowBlur: 3,
              shadowColor: "rgb(29, 41, 81)",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
          },
        ],
        series: conditionalSeriesB
      };
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapGetters({
      monthlyProjectionDataTable: "getMonthlyProjectionDataTable",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    "$vuetify.theme.dark"() {
      this.generateChart();
    },
    windowHeight() {
      this.generateChart();
    },
    windowWidth() {
      this.generateChart();
    },
    monthlyProjectionDataTable(){
      this.generateChart();
    }
  },
});
